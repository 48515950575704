import * as React from "react";
import { graphql } from "gatsby"
import 'bootstrap/scss/bootstrap.scss'
import "../scss/global.scss";
import "../scss/grunticon.scss"
import "slick-carousel/slick/slick.css";
import "../scss/custom.scss";
import Layout from "../components/layout"
import SEO from "../components/seo"

import PageNav from "../components/PageNav/PageNav";
import AreaContent from "../components/AreaContent/NewsDetails";
import Properties from "../components/Properties/Properties";
import NewsStructuredData from "../components/Seo/NewsStructuredData";
import moment from 'moment';

const NewsTemplate = (props) => {
  const GQLPage = props.data?.glstrapi?.blog
  const GlobalModule = props.data?.glstrapi?.globalModule
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  return (
    <div className={'white-header news-details-page'}>
      <SEO title={GQLPage.title+ " | Arabian Estates"} description={`${GQLPage.seo.description}`} />
      <NewsStructuredData 
      image={GQLPage?.banner_image?.url}
       pageurl={pageurl}
        title={GQLPage.title}
        author={GQLPage?.author?.name}
        description={GQLPage.seo.description}
        date={moment(GQLPage.post_on).format("DD MMMM YYYY")}
        />
      <Layout>
        <div className="news-details-page">
          <PageNav page={GQLPage.title} />
          <AreaContent {...GQLPage} />
          <Properties {...GlobalModule?.News_Details_Bottom_Block}/>
        </div>
      </Layout>
    </div>
  )
}

export default NewsTemplate


export const pageQuery = graphql`
  query GetNews($id: ID!) {
    glstrapi {
        blog(id: $id, publicationState: LIVE) {
        title
        id
        post_on
        author{
          name
        }
        description
        category
        video_url
        imagetransforms
        banner_image {
            url
            alternativeText
        }
        seo {
          title
          description
          keywords
          image {
            url
          }
        }
      }
      globalModule {
        News_Details_Bottom_Block {
          column_1_cta_label
          column_1_sub_title
          column_1_title
          column_2_cta_label
          column_2_sub_title
          column_2_title
          column_1_cta_link {
            id
          }
          column_2_bg_image {
            url
            alternativeText
          }
          column_2_cta_link {
            id
          }
        }
      }
    }
  }
`